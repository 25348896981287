.header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0 20px;
	height: 60px;
	box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}

a {
	text-decoration: none;
	color: #000;
}

a :hover {
	text-decoration: none;
	color: #000;
}

/* ////   Mobile   //// */

@media only screen and (max-width: 600px) {
	.header {
		height: 15vh;
		font-size: 2vh;
		border-bottom: .5vh solid #060606;	
	}
	
}